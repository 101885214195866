import React from 'react'

import { Router } from '@reach/router'

import Privacy from './privacy'
import Terms from './terms'

const AppPage = () => {
  return (
    <Router>
      <Privacy default path='privacy' />
      <Terms path='terms' />
    </Router>
  )
}

export default AppPage
